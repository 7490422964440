import React from 'react'

import styles from './navigateArrow.module.scss';

const NavigateArrow = () => {
  return (
    <p className={styles.textArrow}>❯</p>
  )
}

export default NavigateArrow
