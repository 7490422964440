const defaultState = {
  avatar: "https://api.greenbookva.shop/avatar/",
  product: "https://api.greenbookva.shop/product/",
  apm: "https://api.greenbookva.shop/",
  banner: "https://api.greenbookva.shop/banner/",
  recommendation: "https://api.greenbookva.shop/recommendations/",
};

export const imagesRoutesReducer = (state = defaultState) => {
  return state;
};
