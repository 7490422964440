const defaultState = {
    telegram: 'https://t.me/victusic',
    github: 'https://github.com/victusic/Green-Bookva', 
    linkedIn: 'https://www.linkedin.com/in/viktor-khoroshilov-026235277',
    synopsis: '',
    passphrase: 'https://open.spotify.com/user/xklo2w55601u1b164u6vgy9vh?si=B0uK6jZXSJuLxKA1eU7EjA'
}

export const externalLinksReducer = (state = defaultState) =>{
    return state;
}