import React from 'react'
import Pad from '../../../ui/pad/Pad';

import AboutTopText from '../../../modules/forSinglePages/group4/about/aboutTopText/AboutTopText';
import AboutPhrases from '../../../modules/forSinglePages/group4/about/aboutPhrases/AboutPhrases';

const About = () => {
  return (
    <Pad>
      <AboutTopText/>
      <AboutPhrases/>
    </Pad>
  )
}

export default About
