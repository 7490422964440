import React from 'react'

import styles from './discountBadge.module.scss';

const DiscountBadge = () => {
  return (
    <div className={styles.badgePlate}>Скидка</div>
  )
}

export default DiscountBadge
