import React from 'react'

import Pad from '../../../ui/pad/Pad';
import PromotionsPlate from '../../../modules/forSinglePages/group5/promotions/promotionsPlate/PromotionsPlate';

const Promotions = () => {
  return (
    <Pad>
      <PromotionsPlate/>
    </Pad>
  )
}

export default Promotions
