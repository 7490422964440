import React from 'react'

import styles from './noveltyBadge.module.scss';

const NoveltyBadge = () => {
  return (
    <div className={styles.badgePlate}>Новинка</div>
  )
}

export default NoveltyBadge
