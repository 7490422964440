import React from 'react'

import Pad from '../../../ui/pad/Pad';

import BonusProgramText from '../../../modules/forSinglePages/group4/bonusProgram/bonusProgramText/BonusProgramText';

const BonusProgram = () => {
  return (
    <Pad>
      <BonusProgramText/>
    </Pad>
  )
}

export default BonusProgram
