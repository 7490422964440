import React from 'react'

import styles from './salesLederBadge.module.scss';

const SalesLederBadge = () => {
  return (
    <div className={styles.badgePlate}>Лидер продаж</div>
  )
}

export default SalesLederBadge
